import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Alert, AlertIcon, Box, Button, Heading, Text } from "@chakra-ui/react";
import { usePushPath } from "moship";

interface ActivatePlanProps {}

const ActivatePlan: React.FC<ActivatePlanProps> = ({}) => {
  const pushPath = usePushPath();
  return (
    <Box>
      <Alert status="success" variant={"left-accent"} mb="1rem">
        <AlertIcon />
        Tu carrier service está activo
      </Alert>
      <Text marginBottom={"1rem"}>
        Solo falta activar el plan de la app para empezar a calcular tus envíos
        por ciudad.
      </Text>
      <Button
        colorScheme="teal"
        onClick={() => {
          pushPath("/settings");
        }}
      >
        Activar plan
      </Button>
    </Box>
  );
};

export default ActivatePlan;
