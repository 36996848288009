import MoshipContainer from "components/Container";
import Dashboard from "containers/Dashboard";
import { useShop } from "hooks";
import { useSWR } from "moship";
import * as React from "react";
import { useTranslation } from "react-i18next";

import ActivateCarrierService from "./ActivateCarrierService";
import ActivatePlan from "./ActivatePlan";
import ShippingByCity from "./ShippingByCity";

interface CarrierCalculatedShippingProps {}

const CarrierCalculatedShipping: React.FC<
  CarrierCalculatedShippingProps
> = () => {
  const { data: carrierService } = useSWR("/api/v1/shop/carrier-service/");
  const { shop } = useShop();
  const { t } = useTranslation();

  const hasActiveCharge = Boolean(shop?.active_app_charge);
  const hasCarrierService = Boolean(carrierService);

  console.log(hasCarrierService);
  console.log(hasActiveCharge);

  return (
    <Dashboard>
      <MoshipContainer
        title={t(
          "pages.carrier-calculated-shipping.shipping-prices",
          "Shipping prices"
        )}
      >
        <>
          {!hasCarrierService && <ActivateCarrierService />}
          {hasCarrierService && !hasActiveCharge && <ActivatePlan />}
          {hasCarrierService && hasActiveCharge && <ShippingByCity />}
        </>
      </MoshipContainer>
    </Dashboard>
  );
};

export default CarrierCalculatedShipping;
